.employment-week {

}

.employment-week__grid {
  display: grid;
  grid-template-columns: var(--width-grid-label-column) repeat(7, 1fr);
}

.employment-week__shift {
  composes: text-wrap from 'components/calendar-common/css/text-mixins.css';
  text-align: left; 
}

.employment-week__absence {
  composes: text-wrap from 'components/calendar-common/css/text-mixins.css';
}

.employment-week__shift-time {
  font-weight: var(--font-weight-semibold);
}

.employment-week__cell {
  padding: 0 0.5rem;
  text-align: initial;
}
