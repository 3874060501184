.position-cell {
  border-width: 0 0 0 3px;
  border-style: solid;
  align-items: center;
  color: var(--color-text-calendar);
  text-align: left;
  padding: 0.5rem;
}

.position-cell__text {
  composes: text-ellipsis from 'components/calendar-common/css/text-mixins.css'; 
}

.position-cell__toggle {
  composes: reset-button-css from 'components/calendar-common/css/buttons.css'; 
  display: flex;
  align-items: center;
  overflow: hidden;
  text-align: left;
  height: 100%;
}

.position-cell__toggle-text {
  flex: 1;
  composes: text-ellipsis from 'components/calendar-common/css/text-mixins.css';
}

.position-cell__toggle-icon {
  margin-left: 10px;
}
