.shift-month {
  --color-shift-text: #757575;
  --color-shift-full: #01c58a;
  --color-shift-overassigned: #ffa000;
  --color-shift-underassigned: #f44337;
}

.shift-month {
  composes: reset-button-css from 'components/calendar-common/css/buttons.css';
  composes: event-month from 'components/calendar-common/css/events-month.css';
  border-left: 2px solid var(--color-bw-100);
  position: relative;
}

.shift-month--draggable {
  cursor: move;
}

.shift-month__icons-row {
  position: absolute;
  display: flex;
  width: 100%;
  height: 0.5rem;
  padding: 2px;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
  overflow: hidden;
}

.shift-month__icons {
  display: flex;
  flex-direction: row;
}

.shift-month__icon {
  font-size: 0.4rem;
  margin-right: 0.1rem;
}

.shift-month__employments-icon {
  height: 0.4rem;
  width: 0.4rem;
  flex-shrink: 0;
  padding: 0.1rem;
  margin-right: 0.2rem;
  border-radius: 50%;
  background-color: var(--color-shift-full);
}

.shift-month__employments-icon--underassigned {
  background-color: var(--color-shift-underassigned);
}

.shift-month__employments-icon--overassigned {
  background-color: var(--color-shift-overassigned);
}

.shift-month--is-drop-active {
  /* important is needed as we apply inline styles to shifts */
  background-color: var(--color-drop-active) !important;
}

.shift-month--is-drop-active > * {
  pointer-events: none;
}

.shift-month--is-drag-over-active {
  /* important is needed as we apply inline styles to shifts */
  background-color: var(--color-drag-over-active) !important;
}
