.day-note__title {
  margin-right: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.day-note {
  align-items: center;
  background-color: var(--color-grey-700);
  border: none;
  border-radius: 2px;
  color: var(--color-bw-50);
  cursor: pointer;
  display: flex;
  font-size: 0.8em;
  justify-content: space-between;
  margin: 0;
  margin-bottom: 1px;
  outline: none;
  padding: 4px 8px;
}
