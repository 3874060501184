.absence-month {
  --background-color-shift: hsla(0, 0%, 94%, .5);
  --border-shift-color: #d3d3d3;
}

.absence-month {
  composes: event-month from 'components/calendar-common/css/events-month.css'; 
  background-color: var(--background-color-shift);
  border: 1px solid var(--border-shift-color);
}
