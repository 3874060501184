:root {
  /* https://www.colorhexa.com/66CC66 */
  --shift-is-disabled: rgba(217, 220, 222, 1);
  --size-event-section-indent: 0.25rem;
  --width-border-event: 2px;
  --font-size-event: 0.7rem;
  --font-size-event-employment: 0.8rem;
}

.shift-day {
  --color-extra-employess-bg: #eee;
  --color-employees-chip: #01c58a;
  --color-employees-chip--underassigned: #a9353b;
  --color-employees-chip--overassigned: #f4a014;
}

.shift-day:global(.droppable-is-enabled) * {
  pointer-events: none !important;
}

.shift-day {
  composes: reset-button-css from 'components/calendar-common/css/buttons.css';
  display: flex;
  flex-direction: column;
  padding: 
    var(--size-event-section-indent) 
    calc(2 * var(--size-event-section-indent)) 
    0;
  border-left: var(--width-border-event) solid var(--color-bw-100);
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-event);
  line-height: 1.2;
}

.shift-day--draggable {
  cursor: move;
}

.shift-day__subheader {
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-normal);
}

.shift-day__multiday-icon {
  margin-left: var(--size-event-section-indent);
}

.shift-day__header-position {
  font-size: 0.8rem;
  font-weight: bold;
}

.shift-day__icons {
  display: flex;
  align-items: center;
}

.shift-day__icon {
  position: relative;
  padding: 0 var(--size-event-section-indent) 0 0;
  display: flex;
  align-items: baseline;
}

.shift-day__icon span:first-child {
  font-weight: bold;
}

.shift-day__icon:last-child {
  padding-right: 0;
}

.shift-day__icon-text {
  /* add really small space so that icon and text
   * are closer to each other.
   */
  padding-left: 2px;
  vertical-align: super;
}

.shift-day__chips {
  display: flex;
  flex-direction: row;
}

/* reset margin when container is used */
.shift-day__chips .shift-day__chips-chip,
.shift-day__chips .shift-day__icons {
  margin-bottom: 0;
}

.shift-day__chips .shift-day__icons {
  flex: 1;
  justify-content: flex-end;
  align-items: baseline;
}

.shift-day__chips-chip {
  composes: chip from 'components/calendar-common/css/chips.css';
}

.shift-day__employees-chip {
  background-color: var(--color-employees-chip);
  margin-right: 0.2rem;
  flex-shrink: 0;
}
.shift-day__employees-chip--underassigned {
  background-color: var(--color-employees-chip--underassigned);
}

.shift-day__employees-chip--overassigned {
  background-color: var(--color-employees-chip--overassigned);
}

.shift-day__employees-chip.shift-day__employees-chip--full-line {
  margin-right: 100%;
}

.shift-day__employees {
  min-height: calc(var(--size-employee-avatar) + var(--size-event-section-indent));
  width: 100%;
  position: relative;
  font-size: var(--font-size-event-employment);
  display: grid;
  overflow: hidden;
}

.shift-day__employees--fullnames {
  row-gap: 0.5rem;
}

.shift-day--agenda {
  padding: var(--size-event-section-indent);
}

.shift-day--agenda .shift-day__subheader {
  width: 100%;
}

.shift-day__horizontal-group {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--size-event-section-indent);
  flex-wrap: wrap;
  margin-top: calc(0px - var(--size-event-section-indent));
}

.shift-day__horizontal-group--nowrap {
  flex-wrap: nowrap;
}
/* this is a hack to have margin for flex items if flex container wraps */
.shift-day__horizontal-group > * {
  margin-top: var(--size-event-section-indent);
}

.shift-day__horizontal-group--align-bottom {
  align-items: flex-end;
}

.shift-day__horizontal-group--justify-left {
  justify-content: flex-start;
}
/* add margin to elements if they are aligned in flow */
.shift-day__horizontal-group--justify-left > * {
  margin-right: var(--size-event-section-indent);
}

.shift-day--is-drop-active {
  /* important is needed as we apply inline styles to shifts */
  background-color: var(--color-drop-active) !important;
}

.shift-day--is-drop-active > * {
  pointer-events: none;
}

.shift-day--is-drag-over-active {
  /* important is needed as we apply inline styles to shifts */
  background-color: var(--color-drag-over-active) !important;
}
