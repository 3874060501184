.agenda-month {
  --color-separator: #e0e0e0;
}

.agenda-month__cell {
  min-height: 4em;
  padding: 0;
}

.agenda-month__cell-date {
  text-align: right;
  font-size: 0.9em;
  padding: 4px;
  font-weight: bold;
}

.agenda-month__cell-events {
  display: grid;
  gap: inherit;
  padding: 2px;
}

.agenda-month__separator {
  height: 1px;
  width: 90%;
  border: 1px solid var(--color-separator);
  margin: auto;
  opacity: 1;
}

.agenda-month__notes {
  padding: 2px;
}

.agenda-month__week-number {
  align-items: flex-start;
}
