.absence-day {
  composes: reset-button-css from 'components/calendar-common/css/buttons.css';
  display: flex;
  flex-direction: column;
  padding: var(--size-event-section-indent) 0.8rem 0;
  border-left: var(--width-border-event) solid var(--color-bw-100);
  overflow: hidden;
  text-align: left;
  font-weight: 500;
  font-size: var(--font-size-event);
}

.absence-day__header {
  display: flex;
  align-items: center;
  margin-bottom: var(--size-event-section-indent);
  font-size: 0.8rem;
}

.absence-day__reason {
  flex: 1;
}

.absence-day--accepted {
  color: var(--absence-accepted-color);
  border-left-color: var(--absence-accepted-color);
  background-color: var(--absence-accepted-bg-color);
}

.absence-day--new {
  color: var(--absence-new-color);
  border-left-color: var(--absence-new-color);
  background-color: var(--absence-new-bg-color);
}

.absence-day__employment {
  display: flex;
  position: relative;
  min-height: var(--size-employee-avatar);
  font-size: var(--font-size-event-employment);
  margin-bottom: var(--size-event-section-indent);
}

.absence-day__note {
  margin-right: var(--size-event-section-indent);
}

.absence-day--new .absence-day__employment {
  border-color: var(--absence-new-bg-color);
}

.absence-day--accepted .absence-day__employment {
  border-color: var(--absence-accepted-bg-color);
}
