.day-notes-container__label-text {
  flex: 1;
}

.day-notes-container__toggle {
  composes: reset-button-css from 'components/calendar-common/css/buttons.css';
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.day-notes-container__toggle-wrapper {
  display: flex;
  font-size: 0.8em;
}

.day-notes-container__toggle-icon {
  flex: 1;
}

.day-notes-container__toggle-text {
  display: none;
  flex: 1;
  text-transform: uppercase;
  font-weight: bold;
}

.day-notes-container__toggle--with-text .day-notes-container__toggle-icon {
  display: none;
}

.day-notes-container__toggle--with-text .day-notes-container__toggle-text {
  display: initial;
}
