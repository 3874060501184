:root {
  --size-event-month: 32px;
}

.event-month {
  --background-print-event-month: rgba(240, 240, 240, 0.5);
  --border-color-print-event-month: lightgray;

  height: var(--size-event-month);
  min-width: var(--size-event-month);
  font-weight: var(--font-weight-normal);
  border-radius: 2px;
  line-height: var(--size-event-month);
  font-size: 0.8rem;
  position: relative;
  text-align: center;
}
