:root {
  /* https://www.colorhexa.com/66CC66 */

  --background-color-grid-cell: #fff;
  --background-color-grid-cell-outside-shiftplan: #fafafa;
  --border-color-today: #6c6;
  --grid-is-hovered: rgba(171, 227, 171, 1);
  --grid-is-enabled: rgba(215, 242, 215, 1);
  --grid-is-disabled: rgba(228, 230, 231, 1);
}

:global(.droppable--active).grid-cell {
  background: var(--grid-is-enabled) !important;
}

:global(.droppable--hover).grid-cell {
  background-color: var(--grid-is-hovered) !important;
}

.grid-cell--header {
  font-weight: var(--font-weight-semibold);
  text-align: center;
  text-transform: uppercase;
  align-items: center;
  font-size: 0.9em;
}

.grid-cell {
  outline: none;
  text-align: center;
  user-select: none;
  line-height: 1.4;
  min-width: 0;
  min-height: var(--height-cell);
  background-color: var(--background-color-grid-cell);
  display: grid;
  padding: 2px 1px;
  gap: 2px;
  border: solid transparent;
  border-width: 0;
}

.grid-cell--no-gap {
  gap: 0;
  padding: 0;
  border-width: 0;
}

.grid-cell.grid-cell--special-date,
.grid-cell.grid-cell--today {
  border-left-width: 1px;
  border-right-width: 1px;
}

.grid-cell--outside-shiftplan {
  background-color: var(--background-color-grid-cell-outside-shiftplan);
}

.grid-cell--today {
  border-color: var(--border-color-today);
}

.grid-cell--special-date {
  border-color: var(--background-color-special-day);
}

.grid-cell--last-row {
  border-bottom-width: 1px;
}

.grid-cell--droppable {}
