.special-date-compact-container__date {
  composes: reset-button-css from 'components/calendar-common/css/buttons.css';
  height: var(--height-cell);
  width: 100%;
  background-color: var(--background-color-special-day);
}

.special-date-compact-container__grid-cell {
  gap: 0;
  padding: 0;
}

.special-dates-compact-container__label-text {
  composes: special-dates-container__label-text from '../special-dates-container/special-dates-container.css';
}

.special-dates-compact-container__icon {
  line-height: var(--height-cell) !important;
}
