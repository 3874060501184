.grid-table {
  --color-border-grid: #f0f0f0;
  --border-grid: 1px solid var(--color-border-grid);
  --background-color-print-header: #eee;
  --background-color-print: var(--color-bw-100);
  --border-grid-print: 1px solid var(--color-bw-100);
  --padding: 2px;
  --height-cell: 20px;
  --sticky-z-index: 50;
  --width-grid-label-column: 14rem;
  --grid-cell-padding-bottom: 1rem;
}

.grid-table {
  display: inline-grid;
  border: var(--border-grid);
  grid-template-columns: 1fr;
  background-color: var(--color-border-grid);
  gap: 1px;
  min-width: 100%;
  align-content: start;
}

.grid-table__header-cell {
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
  align-items: center;
  justify-content: stretch;
  font-size: 0.9em;
}

.grid-table__label-cell {
  font-weight: var(--font-weight-semibold);
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
  padding: var(--padding);
  overflow: hidden;
}

.grid-table__subgrid {
  grid-column: 1 / -1;
  display: inherit;
  grid-template-columns: inherit;
  grid-gap: inherit;
  gap: inherit;
  -ms-grid-column-span: inherit;
  grid-auto-rows: inherit;
}

.grid-table__subgrid--emulate-gap {
  padding-top: 1px;
}

.grid-table__header {
  display: inherit;
  top: 0;
  background-color: var(--color-border-grid);
}

.grid-table__header--sticky {
  position: sticky;
  /* top header should be over labels column */
  z-index: calc(var(--sticky-z-index) + 1);
}

.grid-table__header--sticky::after {
  display: block;
  content: '';
  position: absolute;
  height: var(--size-material-shadow);
  width: calc(100% + 2px);
  bottom: calc(0px - var(--size-material-shadow));
  left: -1px;
  background: linear-gradient(to bottom, 
    var(--color-material-shadow-inner), 
    transparent);
}

.grid-table__content-cell {
  align-content: flex-start;
}

.grid-table__content-cell--padding-bottom {
  padding-bottom: var(--grid-cell-padding-bottom);
  min-height: calc(var(--size-event-month) + var(--grid-cell-padding-bottom));
}

.grid-table__content-cell--stretch {
  align-content: inherit;
}

.grid-table__subheader-cell {
  font-weight: var(--font-weight-semibold);
  text-align: center;
  align-items: center;
}

.grid-table--print {
  --background-color-grid-cell: var(--color-bw-50);
  --background-color-grid-cell-outside-shiftplan: var(--color-bw-50);
  --size-event-month: 32px;

  border: var(--border-grid-print);
  background-color: var(--background-color-print);
  min-width: 1600px;
}

.grid-table__print-content-cell {
  composes: text-wrap from 'components/calendar-common/css/text-mixins.css';
  align-content: flex-start;
  white-space: pre-wrap;
}

.grid-table__print-header-cell {
  composes: text-wrap from 'components/calendar-common/css/text-mixins.css';
  font-weight: var(--font-weight-semibold);
  --background-color-grid-cell: var(--background-color-print-header); 
}

.grid-table__print-label-cell {
  composes: text-wrap from 'components/calendar-common/css/text-mixins.css';
  composes: grid-table__header-cell;
  padding: 0 0.5rem;
  justify-content: flex-start;
  text-align: left;
}

.grid-table__print-header {
  background-color: var(--color-bw-100);
}
