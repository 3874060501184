.list_day {
  grid-template-columns: 1fr;
}

.list-day__absences {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.list-day__absences--one-column {
  grid-template-columns: 1fr;
}

.list-day__absences-block {
  display: grid;
  grid-template-columns: 1fr;
  gap: inherit;
  grid-template-rows: var(--height-cell) 1fr;
}

.list-day__shifts {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr;
}

.list-day__shifts--notes {
  grid-template-columns: 1fr 2fr 1fr 2fr 2fr;
}
