.reset-button-css {
  outline: 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  font: inherit;
  text-transform: inherit;
  background: inherit;
  color: inherit;
  align-items: initial;
}
