.grid-row__cell--sticky {
  position: sticky;
  left: 0;
  z-index: var(--sticky-z-index);
  display: grid;
}

.grid-row__cell--total {
  position: sticky;
  right: 0;
  z-index: var(--sticky-z-index);
  display: grid;
  background: var(--color-grey-100);
}

.grid-row__cell--sticky::after {
  display: block;
  content: '';
  position: absolute;
  width: var(--size-material-shadow);
  height: calc(100% + 2px);
  top: -1px;
  right: calc(0px - var(--size-material-shadow));
  background:
    linear-gradient(
      to right,
      var(--color-material-shadow-inner),
      transparent
    );
}

.grid-row--with-overlay {
  position: relative;
}

.grid-row__overlay {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
