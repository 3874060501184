.grid-header-month__month {
  text-transform: uppercase;
  font-size: 1em;
}

.grid-header-month__week {
  font-size: 0.9em;
}

.grid-header-month__day {
  composes: reset-button-css from 'components/calendar-common/css/buttons.css';
  font-size: 0.8em;
}

.grid-header-month__label {
  grid-row: 1 / 4;
}

.grid-header-month__total {
  grid-row: 1 / 4;
  background: var(--color-grey-100);
  position: sticky;
  right: 0;
}

.grid-header-month__label--sticky {
  position: sticky;
  top: 0;
  left: 0;
  z-index: calc(var(--sticky-z-index) + 1);
}

.grid-header-month__label--sticky::after {
  display: block;
  content: '';
  position: absolute;
  width: var(--size-material-shadow);
  height: calc(100% + 2px);
  top: -1px;
  right: calc(0px - var(--size-material-shadow));
  background:
    linear-gradient(
      to right,
      var(--color-material-shadow-inner),
      transparent
    );
}
