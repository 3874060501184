.pagination-mixin {
  display: flex;
  grid-column: 1 / -1;
  background: var(--color-bw-50);
}

.pagination-mixin__button {
  composes: reset-button-css from 'components/calendar-common/css/buttons.css';
  font-size: 1.2em;
  color: var(--color-primary-600);
  position: sticky;
  left: 0;
  width: 100vw;
  padding: 1rem 0;
}

.pagination-mixin__button:disabled {
  color: var(--color-grey-600);
  animation: loading-pulse 1.5s ease-out infinite;
}

@keyframes loading-pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}
