:root {
  --color-material-shadow-inner: rgba(0, 0, 0, 0.24);
  --color-material-shadow-outer: rgba(0, 0, 0, 0.12);
  --size-material-shadow: 4px;
}

.material-card {
  background: #fff;
  border-radius: 2px;
  box-shadow:
    0 1px var(--size-material-shadow) var(--color-material-shadow-outer),
    0 1px 2px var(--color-material-shadow-inner);
}

.material-shadow-right {
  display: block;
  content: '';
  position: absolute;
  width: var(--size-material-shadow);
  right: calc(0 - var(--size-material-shadow));
  background:
    linear-gradient(
      to right,
      var(--color-material-shadow-inner),
      transparent
    );
}

.material-shadow-bottom {
  display: block;
  content: '';
  position: absolute;
  height: var(--size-material-shadow);
  bottom: calc(0 - var(--size-material-shadow));
  background:
    linear-gradient(
      to bottom,
      var(--color-material-shadow-inner),
      transparent
    );
}
