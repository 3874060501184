.special-dates {
  background-color: orange;
  color: var(--color-grey-700);
  padding: 4px 8px;
  font-size: 0.9em;
  overflow: hidden;
}

.special-date {
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
}

.special-date__label-cell {
  color: orange;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}
