.day-notes-popup {
  composes: material-card from 'components/calendar-common/css/material-cards.css';
  position: absolute;
  display: none;
  top: 0;
  z-index: 500;
  width: 100%;
  top: -2.5em;
  min-width: 150px;
  max-width: 250px;
}

.day-notes-popup--is-open {
  display: block;
}

.day-notes-popup__header-wrapper {
  padding: 10px;
  font-size: 1.1em;
  color: var(--color-grey-600);
}

.day-notes-popup__header-wrapper--is-special {
  background-color: var(--background-color-special-day);
  color: var(--text-color-special-day);
}

.day-notes-popup__header {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
}

.day-notes-popup__header-title {
  text-align: left;
  font-weight: normal;
  margin-bottom: 5px;
}

.day-notes-popup__subheader {
  font-size: 2em;
  text-align: left;
}

.day-notes-popup__container {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.day-notes-popup__note-title {
  margin-right: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.day-notes-popup__toggle-button {
  font-size: 1.2em;
  text-align: center;
  display: flex;
}

.day-notes-popup__add-note {
  composes: reset-button-css from 'components/calendar-common/css/buttons.css';
  composes: day-notes-popup__toggle-button;
  margin: 0 auto 10px auto;
}

.day-notes-popup__close-button {
  composes: reset-button-css from 'components/calendar-common/css/buttons.css';
  composes: day-notes-popup__toggle-button;
  align-self: flex-start;
}

.day-notes-popup__special-date-title {
  margin-bottom: 10px;
}
