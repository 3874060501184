.time-grid-event__container {
  --event-padding: 2px;
  --hovered-event-z-index: 20;
  --edited-event-z-index: 40;
}
.time-grid-event {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.time-grid-event__container {
  position: absolute;
  z-index: 1;
  width: calc(50% - var(--event-padding));
  transform: translate(var(--event-padding), var(--event-padding));
}

.time-grid-event__container--edited-original {
  opacity: 0.5;
}

.time-grid-event__container--fixed {
  position: fixed;
  max-height: 300px;
  z-index: var(--draggable-event-z-index);
}

.time-grid-event__container--full-width {
  width: calc(100% - calc(2 * var(--event-padding)));
}

.time-grid-event__container--hover {
  z-index: var(--hovered-event-z-index);
}

.time-grid-event__container--edited {
  /* need important to override inline styles */
  z-index: var(--edited-event-z-index) !important;
  width: 100%;
  left: 0;
  cursor: move;
}

.time-grid-event__drag-handle {
  composes: reset-button-css from 'components/calendar-common/css/buttons.css';
  cursor: ns-resize;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  font-size: 0.7rem;
  padding-bottom: 2px;
  height: 1rem;
  line-height: 1;
}

.time-grid-event__drag-handle--small {
  height: 0.7rem;
}
