.employee__container {
  --z-index-employee-hovered: 20;
}
.employee__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border-color: inherit;
}

.employee__container--draggable {
  cursor: move;
}

.employee__container--fullnames {
  overflow: hidden;
}

/* when Employee container with actual employment
 * is hovered bring it on top, so that image/icon
 * is more visible.
 * need important to override inline styles
 */
.employee__container--has-hover:hover {
  z-index: var(--z-index-employee-hovered) !important;
}

/* these settings are used to display initials */
.employee {
  border-radius: calc(var(--size-employee-avatar) / 2);
  height: var(--size-employee-avatar);
  width: var(--size-employee-avatar);
  background-color: var(--color-extra-employess-bg);
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  line-height: var(--size-employee-avatar);
  overflow: hidden;
  border: 1px solid;
  border-color: inherit;
  margin-right: 0.5rem;
  color: var(--color-bw-100);
}

.employee__name {
  composes: text-ellipsis from 'components/calendar-common/css/text-mixins.css';
}

/* 1. If Fullname is not selected as an
 * option to calendar only display icon.
 *
 * 2. If Employee is not being dragged,
 * hide full name.
 */
.employee__container:not(.employee__container--fullnames) .employee__name {
  display: none;
}

/*  dnd styles */
:global(.draggable--handle).employee__container {
  background: var(--color-bw-50);
  padding: 4px;
  transform: translateZ(0);
}

:global(.draggable--handle).employee__container .employee__name {
  display: block;
}

.employee--extra {
  z-index: 10;
  display: flex;
  justify-content: center;
  line-height: var(--size-employee-avatar);
  height: var(--size-employee-avatar);
  width: var(--size-employee-avatar);
  flex-shrink: 0;
  color: inherit;
}

/* reset styles for fullnames */
.employee--fullnames {}


/* show always when dragged */
.employee--draggable-handle {
  min-height: 3rem;
  padding: 10px;
  min-width: 14rem;
  background-color: var(--color-bw-50);
}

.employee--draggable-handle .employee {
  position: relative;
  left: 0;
}
