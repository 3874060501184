.toggle-cell__toggle {
  composes: reset-button-css from 'components/calendar-common/css/buttons.css';
  display: flex;
  align-items: center;
  overflow: hidden;
  text-align: left;
  height: 100%;
}

.toggle-cell__toggle-text {
  flex: 1;
  composes: text-ellipsis from 'components/calendar-common/css/text-mixins.css';
}

.toggle-cell__toggle-icon {
  margin-left: 10px;
}
