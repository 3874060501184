.absence-month {
  composes: reset-button-css from 'components/calendar-common/css/buttons.css';
  composes: event-month from 'components/calendar-common/css/events-month.css';
}

.absence-month--accepted {
  color: var(--absence-accepted-color);
  background-color: var(--absence-accepted-bg-color);
}

.absence-month--new {
  color: var(--absence-new-color);
  background-color: var(--absence-new-bg-color);
}
