.day-notes-compact {
  align-content: center;
  align-items: center;
  background: initial;
  border: none;
  display: flex;
  height: 100%;
  margin: 0;
  outline: none;
  padding: 0;
  width: 100%;
}

.day-notes-compact:not(:disabled) {
  cursor: pointer;
}

.day-notes-compact:disabled {
  pointer-events: none;
}

.day-notes-compact__notes-count {
  margin: 0 2px;
  flex: 1;
  background-color: var(--color-grey-700);
  color: var(--color-bw-50);
  padding: 2px;
  margin-bottom: 1px;
  border-radius: 4px;
  font-size: 0.9em;
  line-height: 1;
}

.day-notes-compact__add-icon {
  flex: 1;
  text-align: center;
  visibility: hidden;
}

.day-notes-compact:hover .day-notes-compact__add-icon {
  visibility: visible;
}
