.day-notes {
  padding: 2px;
  position: relative;
  height: 100%;
}

.day-notes__list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.day-notes__button {
  align-items: center;
  background: inherit;
  border: none;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.8em;
  font-weight: bold;
  margin: 0;
  outline: none;
  padding: 0 4px;
  text-align: center;
}

.day-notes__button-text {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.day-notes__button-icon {
  margin-left: 5px;
}

.day-notes__more {
  &:empty {
    padding: 0;
  }
}

.day-notes__more--is-empty {
  line-height: 4.6;
}

.day-notes__add {
  flex: 1;
}

.day-notes__add .day-notes__button-text,
.day-notes__add .day-notes__button-icon {
  visibility: hidden;
}

.day-notes__add:hover .day-notes__button-text,
.day-notes__add:hover .day-notes__button-icon {
  visibility: visible;
}

.day-notes__label-cell {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}
