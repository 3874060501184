.position-week {
  
}

.position-week__shift {
  text-align: left;
  margin: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px dashed var(--color-bw-100);
}

.position-week__shift-header {
  display: flex;
  flex-direction: row;
}

.position-week__shift-time {
  flex: 1;
  font-weight: bold;
}

.position-week__grid {
  display: grid;
  grid-template-columns: var(--width-grid-label-column) repeat(7, 1fr);
}
