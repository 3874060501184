.delete-box {
  position: fixed;
  bottom: 50px;
  width: 20%;
  left: 40%;
  min-width: 250px;
  height: 50px;
  border: 1px solid var(--color-error-700);
  color: var(--color-error-700);
  visibility: hidden;
  line-height: 50px;
  border-radius: 8px;
  background-color: var(--color-drop-active);
  text-align: center;
  z-index: 51;
  cursor: pointer;
}

.delete-box--drag-over-active {
  background-color: var(--color-drag-over-active);
}

.delete-box--drop-active {
  visibility: visible;
}

.delete-box--drop-active > * {
  pointer-events: none;
}
