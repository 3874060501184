:root {
  /* https://www.colorhexa.com/66CC66 */
  --shift-is-hovered: rgba(159, 223, 159, 1);
  --shift-is-enabled: rgba(205, 238, 205, 1);
  --shift-is-disabled: rgba(217, 220, 222, 1);
  --draggable-event-z-index: calc(var(--sticky-z-index) + 2);
}

:global(.draggable-is-dragged),
:global(.draggable--active) {
  pointer-events: initial;
}

:global(.droppable-is-enabled) * {
  pointer-events: none;
}

:global(.droppable-is-disabled) {
  pointer-events: none;
}

:global(.droppable-is-disabled) * {
  pointer-events: none;
}

:global(.droppable--active),
:global(.droppable-is-enabled) {
  background: var(--shift-is-enabled) !important;
  pointer-events: initial;
}

:global(.draggable--handle) {
  z-index: var(--draggable-event-z-index);
}

:global(.droppable--hover),
:global(.droppable-is-hovered.droppable-is-enabled) {
  background-color: var(--shift-is-hovered) !important;
}

:global(.droppable--active) * {
  pointer-events: none;
}
