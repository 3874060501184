.agenda-shift {
  flex: 1;
}

.agenda-shift__employments-container {
  overflow: hidden;
  flex: 1;
}

.agenda-shift__info-wrapper {}
