.print {
  padding: 20px;
  display: inline-flex;
  align-items: stretch;
  flex-direction: column;
  min-width: 1600px;
  min-height: 900px;
}

.InternetExplorer .print {
  min-width: 100% !important;
}

.print__header {
  display: flex;
  padding-bottom: 20px;
  align-items: center;
}

.print__header-range {
  font-weight: bold;
  font-size: 1.1em;
  flex: 1;
}
