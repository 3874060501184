.summary-cell {
  --color-summary-cell: var(--color-grey-700);

  text-align: center;
  font-size: 0.9rem;
  color: var(--color-summary-cell);
  background: inherit;
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 30px; /* 32 - 1px grid gap */
}

.summary-cell--red {
  --color-summary-cell: var(--color-error-700);
}

.summary-cell--green {
  --color-summary-cell: var(--color-primary-400);
}

.summary-cell--yellow {
  --color-summary-cell: var(--color-warning-700);
}
